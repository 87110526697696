define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "app": {
      "title": "ADDITIVE+ ANFRAGEN"
    },
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "ANFRAGEN",
        "presentationName": "ADDITIVE+ ANFRAGEN"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "GUTSCHEINE",
        "presentationName": "ADDITIVE+ GUTSCHEINE"
      },
      "4": {
        "name": "INHALTE",
        "presentationName": "ADDITIVE+ INHALTE"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GÄSTE INTRANET",
        "presentationName": "ADDITIVE+ GÄSTE INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Gelöschter Benutzer",
      "description": "Hier werden die Aktivitäten aller Benutzer Ihrer Organisation innerhalb der App chronologisch aufgelistet.",
      "empty": "Keine Aktivitäten vorhanden",
      "entries": "Einträge",
      "page": "Seite",
      "time": "{time} Uhr",
      "title": "Aktivitätenprotokoll"
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      }
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS Integrationen",
            "campaigns": "Kampagnen",
            "landingPages": "Landingpages",
            "leadIntegrations": "Lead-Integrations",
            "pmsIntegrations": "Hotel Software Integrationen",
            "settings": "Einstellungen",
            "surveys": "Umfragen",
            "thirdPartyTokens": "ADDITIVE+ APPS Schnittstellen",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Inhalt",
            "contentType": "Inhaltstyp"
          }
        },
        "description": "Das Objekt kann nicht gelöscht werden, da es von anderen Inhalten verwendet wird. Lösen Sie die Abhängigkeiten bevor Sie das Objekt löschen.",
        "title": "Das Objekt ist in Verwendung"
      },
      "discardChanges": {
        "continueEditing": "Weiterarbeiten",
        "discardAction": "Verwerfen",
        "message": "Sollen ungespeicherte Änderungen verworfen werden?",
        "title": "Achtung!"
      },
      "error": {
        "message": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
        "title": "Etwas ist schief gelaufen"
      },
      "noPermission": {
        "message": "Sie haben leider keine Berechtigung um diese Aktion durchzuführen. Wenden Sie sich an Ihren Administrator.",
        "title": "Fehlende Berechtigung"
      }
    },
    "errors": {
      "channelCodeExists": "Dieser Kanal-Code wird bereits verwendet.",
      "email": "Ungültige E-Mail-Addresse",
      "establishmentCodeExists": "Dieser Betriebscode wird bereits verwendet.",
      "failedDependency": "Dieser Kanal kann nicht gelöscht werden, da er Anfragen enthält.",
      "multipleEmailsValidation": "Ungültige E-Mail-Addressen",
      "nameExists": "Dieser Name wird bereits verwendet.",
      "required": "Dieser Wert darf nicht leer sein",
      "title": "Fehler",
      "wrongPlaceholder": "Im Betreff ist nur {name} als Platzhalter erlaubt."
    },
    "global": {
      "actions": {
        "activate": "Aktivieren",
        "cancel": "Abbrechen",
        "change": "Ändern",
        "create": "Erstellen",
        "deactivate": "Deaktivieren",
        "delete": "Löschen",
        "download": "Herunterladen",
        "export": "Exportieren",
        "false": "Deaktiviert",
        "filter": "Filtern",
        "logout": "Abmelden",
        "more": "Mehr",
        "remove": "Entfernen",
        "rename": "Umbenennen",
        "retry": "Wiederholen",
        "save": "Speichern",
        "search": "Suchen",
        "true": "Aktiviert"
      },
      "dialog": {
        "change": {
          "alpinebits": "AlpineBits",
          "casablanca": "Casablanca",
          "description": "Soll dieser Wert wirklich geändert werden?",
          "generateTracking": "UTM-Parameter überschreiben",
          "mts": "MTS",
          "multipleEstablishments": "Weitere Betriebe",
          "reGuest": "Re:Guest"
        },
        "delete": "Soll dieses Element endgültig gelöscht werden?",
        "error": {
          "sendEnquiry": {
            "description": "Die E-Mail Zustellung kann nicht aktiviert werden, da keine Empfänger angegeben wurden.",
            "title": "Achtung!"
          }
        }
      },
      "email": "E-Mail-Adresse",
      "emails": "E-Mail-Adressen",
      "false": "Nein",
      "languages": {
        "de": "Deutsch",
        "en": "Englisch",
        "fr": "Französisch",
        "it": "Italienisch",
        "nl": "Niederländisch",
        "pl": "Polnisch"
      },
      "name": "Name",
      "status": {
        "false": "Nicht aktiv",
        "true": "Aktiv"
      },
      "telephone": "Telefon-Nr.",
      "toast": {
        "deleteLanguage": {
          "success": "{lang} wurde erfolgreich gelöscht."
        },
        "error": {
          "savedChanges": "Ein unerwarteter Fehler ist aufgetreten"
        },
        "success": {
          "savedChanges": "Die Aktion war erfolgreich"
        },
        "update": {
          "actionLabel": "Neu laden",
          "description": "Ungespeicherte Änderungen gehen verloren.",
          "title": "Update verfügbar"
        }
      },
      "true": "Ja"
    },
    "instance": {
      "channels": {
        "channel": {
          "alpinebits": "AlpineBits",
          "alpinebitsSubheader": "AlpineBits",
          "autoresponder": {
            "subject": "Betreff",
            "text": "Text"
          },
          "autoresponderSubheader": "Autoresponder",
          "autoresponderText": "E-Mail Text",
          "casablanca": "Casablanca",
          "casablancaCompanyId": "Betriebscode",
          "casablancaCustomerId": "Kundencode",
          "casablancaSubheader": "Casablanca",
          "channelCode": "Kanalcode",
          "channelInfos": "Kanalinformationen",
          "cooperationChannel": "Kooperations-Kanal",
          "eliteMailExchange": "Elite Mail-Exchange",
          "enquiryMailIntro": "E-Mail Einleitung",
          "enquiryReceivers": {
            "description": "Geben Sie hier die E-Mail-Addressen der Empfänger ein.",
            "title": "Empfänger der Anfragen"
          },
          "establishmentCode": "Betriebscode",
          "establishmentConfig": "Betriebseinstellungen",
          "establishmentsRequired": "Betrieb erforderlich",
          "medium": "Medium",
          "mts": "MTS",
          "mtsId": "Betriebscode",
          "mtsPassword": "Passwort",
          "mtsSubheader": "MTS",
          "mtsUsername": "Benutzername",
          "name": "Name",
          "phoenixMailExchange": "Phoenix Mail-Exchange",
          "reGuest": "Re:Guest",
          "reGuestCustomerId": "Betriebscode",
          "reGuestFacilityCode": "FacilityCode",
          "reGuestPassword": "Passwort",
          "reGuestSubheader": "Re:Guest",
          "reGuestUsername": "Benutzername",
          "sendAutoresponder": "Autoresponder aktivieren",
          "sendEmail": "E-Mail Zustellung",
          "sendEnquiry": "E-Mail Zustellung",
          "source": "Quelle",
          "tracking": "Tracking"
        },
        "dialogs": {
          "create": {
            "label": "Name",
            "title": "Neuen Kanal erstellen"
          },
          "delete": {
            "content": "Soll der Kanal endgültig gelöscht werden?",
            "title": "Löschen des Kanals “{name}”"
          },
          "deleteError": {
            "content": "Dieser Kanal kann nicht gelöscht werden, da es Einträge gibt, die damit verbunden sind.",
            "title": "Hoppla!"
          },
          "enquiryReceivers": {
            "placeholder": "E-Mail",
            "title": "Anfrage Empfänger"
          },
          "establishmentCode": {
            "error": "Dieser Wert darf nicht leer sein",
            "label": "Code"
          },
          "integrationError": {
            "alpinebits": "Zum Aktivieren ist ein Betriebscode notwendig.",
            "casablanca": "Zum Aktivieren sind der Betriebscode und der Kundencode notwendig.",
            "mts": "Zum Aktivieren sind der Betriebscode, der Benutzername und das Password notwendig.",
            "reGuest": "Zum Aktivieren sind der Betriebscode, der Benutzername und das Password notwendig.",
            "title": "Achtung!"
          },
          "rename": {
            "label": "Name",
            "title": "Kanal umbenennen"
          },
          "sendEnquiryError": {
            "content": "Zum Aktivieren ist mindestens ein Empfänger erforderlich.",
            "title": "Hoppla!"
          }
        },
        "infos": {
          "alpinebits": "AlpineBits",
          "casablanca": "Casablanca",
          "email": "E-Mail",
          "mts": "MTS",
          "phoenix": "Phönix",
          "reguest": "Re:Guest"
        }
      },
      "enquiries": {
        "dialogs": {
          "exportError": {
            "content": "Beim Exportieren ist ein Fehler aufgetreten. Bitte versuchen sie es später noch einmal!",
            "title": "Hoppla!"
          }
        },
        "enquiry": {
          "additionals": "Zusätzliche Daten",
          "attachments": "Anhänge",
          "createdAt": "eingegangen am",
          "delete": "Anfrage löschen",
          "dialogs": {
            "deleteEnquiry": {
              "content": "Die Anfrage wird vollständig gelöscht. Wirklich fortfahren?",
              "title": "Anfrage löschen"
            }
          },
          "error": "Beim Laden der Elemente ist ein Fehler aufgetreten",
          "establishment": "Betrieb",
          "establishments": "Betriebe",
          "false": "Nein",
          "genderIntl": "Geschlecht",
          "language": "Anfrage-Sprache",
          "organisation": {
            "addressCountry": "Land",
            "catalogue": "Katalog",
            "email": "E-Mail-Adresse",
            "faxNumber": "Fax",
            "image": "Bild",
            "language": "Sprache",
            "legalName": "Name",
            "logo": "Logo",
            "nationality": "Nationalität",
            "newsletter": "Newsletter",
            "postOfficeBoxNumber": "Postfach",
            "postalCode": "PLZ",
            "streetAddress": "Straße",
            "taxId": "MwSt.Nr.",
            "telephone": "Telefon",
            "title": "Organisation",
            "url": "Website",
            "vatId": "Steuer Nr."
          },
          "organisationLocality": "Ort",
          "person": {
            "additional": "Zusätzliche Daten",
            "additionalName": "2. Vorname",
            "addressCountry": "Land",
            "addressLocality": "Stadt",
            "birthDate": "Geburtstag",
            "catalogue": "Katalog",
            "email": "E-Mail-Adresse",
            "familyName": "Nachname",
            "faxNumber": "Fax",
            "fullname": "Vollständiger Name",
            "gender": "Geschlecht",
            "genderOptions": {
              "f": "Weiblich",
              "m": "Männlich",
              "none": "Keine Angabe"
            },
            "givenName": "Vorname",
            "honorificPrefix": "Anrede",
            "image": "Bild",
            "language": "Sprache",
            "mobilephone": "Mobil",
            "nationality": "Nationalität",
            "newsletter": "Newsletter",
            "postOfficeBoxNumber": "Postfach",
            "postalCode": "PLZ",
            "salutationLanguage": "Anrede-Sprache",
            "streetAddress": "Straße",
            "taxId": "MwSt.Nr.",
            "telephone": "Telefon",
            "title": "Kontaktdaten",
            "url": "Website"
          },
          "personLocality": "Ort",
          "product": {
            "title": "Produkt"
          },
          "roomStays": {
            "adults": "Erwachsene",
            "amount": "Preis",
            "children": "Kinder",
            "endDate": "Abreise",
            "mealPlan": "Verpflegung",
            "mealPlanRates": {
              "1": "All Inclusive",
              "10": "Vollpension",
              "12": "Halbpension",
              "14": "Ohne Verpflegung",
              "3": "Übernachtung mit Frühstück"
            },
            "number": {
              "0": "Aufenthalt Nr. 1",
              "1": "Aufenthalt Nr. 2",
              "2": "Aufenthalt Nr. 3",
              "3": "Aufenthalt Nr. 4",
              "4": "Aufenthalt Nr. 5",
              "5": "Aufenthalt Nr. 6",
              "6": "Aufenthalt Nr. 7"
            },
            "offer": "Angebot",
            "ratePlan": "Preistyp / Angebot",
            "roomClassificationCode": "Typ Wohneinheit",
            "roomClassificationCodes": {
              "13": "Appartement",
              "3": "Suite",
              "42": "Zimmer"
            },
            "roomType": "Name Wohneinheit",
            "startDate": "Anreise",
            "title": "Unterkunft",
            "year": "{count, plural, =1 {Jahr} other {Jahre}}"
          },
          "status": {
            "alpinebits": "Abholung mittels AlpineBits",
            "casablanca": "Abholung mittels Casablanca",
            "emptyText": "Kein Status vorhanden",
            "enquiryReceivers": "Zustellung an Anfragenden",
            "failure": "fehlerhaft",
            "mts": "Abholung mittels MTS",
            "pending": "in Bearbeitung",
            "pickingUpBy": "Abholung durch",
            "reGuest": "Abholung mittels Re:Guest",
            "receivers": "Zustellung an Empfänger",
            "shippingTo": "Zustellung an",
            "success": "erfolgreich"
          },
          "superAdminTrackingMediumDisplay": "Medium",
          "superAdminTrackingSourceDisplay": "Quelle",
          "text": "Text",
          "title": "Titel",
          "tracking": {
            "campaign": "Kampagne",
            "cooperationMedium": "Medium",
            "cooperationSource": "Quelle",
            "entryPage": "Einstiegsseite",
            "medium": "Medium",
            "referrer": "Referrer",
            "source": "Quelle",
            "title": "Tracking",
            "tracking": "Tracking"
          },
          "true": "Ja"
        },
        "export": {
          "action": "Exportieren",
          "dialog": {
            "dateRange": "Zeitraum",
            "description": "Wählen Sie die gewünschte Anfragenliste, welche Sie herunterladen möchten.",
            "noDateRangeSelected": "Kein Zeitraum ausgewählt",
            "options": {
              "all": "Alle Anfragen herunterladen",
              "filtered": "Aktuell gefilterte Anfragen herunterladen",
              "timePeriod": "Anfragen nach Zeitraum herunterladen"
            },
            "title": "Anfragen exportieren"
          },
          "toast": {
            "message": "Export wird vorbereitet..."
          }
        },
        "filter": {
          "button": "Filtern",
          "options": {
            "documents": "Dokumente ",
            "images": "Bilder",
            "pdf": "PDF"
          },
          "title": "Filtern nach"
        },
        "groups": {
          "date": {
            "lastWeek": "Letzte Woche",
            "thisMonth": "Diesen Monat",
            "thisWeek": "Diese Woche",
            "today": "Heute",
            "yesterday": "Gestern"
          }
        },
        "listView": {
          "channel": "Kanal",
          "date": "Datum",
          "email": "E-Mail-Adresse",
          "name": "Name",
          "status": "Status"
        },
        "titleCount": "Anfragen {count}"
      },
      "establishments": {
        "dialogs": {
          "code": {
            "label": "Betriebscode",
            "title": "Betriebscode ändern"
          },
          "create": {
            "code": "Betriebscode",
            "label": "Betriebsname",
            "title": "Neuen Betrieb erstellen"
          },
          "delete": {
            "content": "Soll der Betrieb endgültig gelöscht werden?",
            "title": "Löschen des Betriebs “{name}”"
          },
          "enquiryReceivers": {
            "placeholder": "E-Mail",
            "title": "Anfrage Empfänger"
          },
          "rename": {
            "label": "Betriebsname",
            "title": "Betrieb umbenennen"
          },
          "sendEnquiryError": {
            "content": "Zum Aktivieren ist mindestens ein Empfänger erforderlich.",
            "title": "Hoppla!"
          }
        },
        "establishment": {
          "alpinebits": "AlpineBits",
          "alpinebitsSubheader": "AlpineBits",
          "code": "Betriebscode",
          "enquiryReceivers": "Empfänger der Anfragen",
          "establishmentInfos": "Betriebsinformationen",
          "phoenixMailExchange": "Phoenix Mail-Exchange",
          "sendEmail": "E-Mail Zustellung",
          "sendEnquiry": "E-Mail Zustellung"
        }
      }
    },
    "languageSelect": {
      "add": "Sprache hinzufügen",
      "ar": "Arabisch",
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch",
      "it": "Italienisch",
      "nl": "Niederländisch",
      "pl": "Polnisch",
      "remove": "Sprache entfernen",
      "removeDescription": "Sind Sie sicher diese Sprache entfernen zu wollen?",
      "ru": "Russisch"
    },
    "pageNotFound": {
      "button": "Zur Startseite",
      "content": "Leider konnte die gesuchte Seite nicht gefunden werden! <br>Entweder ist die URL nicht korrekt oder die Seite wurde entfernt bzw. umbenannt.",
      "title": "Seite wurde nicht gefunden!"
    },
    "reports": {
      "adultCount": {
        "title": "Anzahl Erwachsene",
        "value": "{count, plural, =0 {kein Erwachsener} =1 {# Erwachsener} other {# Erwachsene}}"
      },
      "adultCountMonthData": {
        "description": "In der anschließenden Tabelle finden Sie eine Auflistung der im gewählten Zeitraum erfolgten Anfragen basierend auf der Anzahl an mitreisenden Erwachsenen. Außerdem erfahren Sie, welcher Anteil davon in die Gruppe \"nur Erwachsene\" bzw. \"Erwachsene mit Kindern\" fällt.",
        "title": "Angefragte Aufenthalte nach Anzahl an mitreisenden Erwachsenen "
      },
      "age": {
        "title": "Alter",
        "value": "{count, plural, =1 {# Jahr} other {# Jahre}}"
      },
      "campaign": "Kampagne",
      "channelsData": "Kanäle",
      "childAgeData": "Anzahl an mitreisenden Kindern nach Alter",
      "childCount": {
        "title": "Anzahl Kinder",
        "value": "{count, plural, =0 {keine Kinder} =1 {# Kind} other {# Kinder}}"
      },
      "childCountData": "Angefragte Aufenthalte nach Anzahl an mitreisenden Kindern",
      "description": "Im Folgenden erhalten Sie einen Gesamtüberblick über die im gewählten Zeitraum erzielten Anfragen sowie einen Einblick in diverse Detailauswertungen.",
      "filter": {
        "all": "Alle",
        "allChannels": "Alle Kanäle",
        "campaign": "Kampagne",
        "channel": "Kanal",
        "endDate": "Datum bis",
        "medium": "Medium",
        "none": "Keine",
        "source": "Quelle",
        "startDate": "Datum von",
        "timespan": "Zeitraum",
        "updateFilter": "Filter aktualisieren"
      },
      "general": {
        "count": "Anfragen im Zeitraum",
        "countLastPeriod": "Im Vergleichszeitraum {from}-{to}",
        "countLastYear": "Im Vergleichszeitraum {year}",
        "daysBeforeArrivalAverage": "Ø Anfragevorlaufzeit in Tagen",
        "perDay": "Ø Anzahl Anfragen pro Tag",
        "perWeek": "Ø Anzahl Anfragen pro Woche",
        "title": "Anfragen",
        "yearToDate": "Zum Vorjahr"
      },
      "generalGuest": {
        "averageAdults": "Ø Anzahl Erwachsene",
        "averageChildren": "Ø Anzahl Kinder",
        "averageChildrenAge": "Ø Alter der Kinder in Jahren ",
        "averageStays": "Ø Aufenthaltsdauer in Tagen"
      },
      "guestNightsPerMonthData": {
        "description": "In der nachstehenden Übersicht erfahren Sie mehr über die im gewählten Zeitraum angefragten Nächtigungen pro Monat. Dabei wird jede mitreisende Person berücksichtigt und zwischen Erwachsenen und Kindern differenziert.",
        "title": "Angefragte Nächtigungen pro Monat"
      },
      "guestsPerArrivalMonthData": {
        "description": "Anhand der folgenden Statistik wird die Anzahl an im gewählten Zeitraum angefragten Ankünften pro Monat dargestellt. Analog zu den vorhergehenden Detailauswertungen, wird dabei zwischen anreisenden Erwachsenen und Kindern unterschieden.",
        "title": "Angefragte Ankünfte pro Monat"
      },
      "languages": {
        "de": "Deutsch",
        "en": "Englisch",
        "fr": "Französisch",
        "it": "Italienisch",
        "nl": "Niederländisch",
        "pl": "Polnisch",
        "ru": "Russisch",
        "se": "Spanisch"
      },
      "languagesData": "Sprachen",
      "medium": "Medium",
      "source": "Quelle",
      "stayCountData": {
        "description": "Die nachfolgende Tabelle kategorisiert die im gewählten Zeitraum generierten Anfragen basierend auf der jeweiligen Aufenthaltsdauer und unterscheidet dabei zwischen Anfragen mit ausschließlich Erwachsenen und jenen mit mitreisenden Kindern.",
        "title": "Angefragte Aufenthalte nach Aufenthaltsdauer"
      },
      "stayRequests": "Unterkunftsanfragen",
      "tableFields": {
        "adultOnly": "Nur Erwachsene",
        "adults": "Erwachsene",
        "age": "Alter",
        "campaign": "Kampagne",
        "channel": "Kanal",
        "children": "Kinder",
        "count": "Anzahl",
        "daily": "Täglich",
        "days": "Tage",
        "language": "Sprache",
        "medium": "Medium",
        "month": "Monat",
        "name": "Name",
        "source": "Quelle",
        "stays": {
          "4to6": "4-6",
          "7to14": "7-14",
          "over15": "> 15",
          "under4": "1-3"
        },
        "total": "Insgesamt",
        "weekly": "Wöchentlich",
        "withChildren": "Mit Kindern"
      },
      "title": "Auswertungen",
      "utmParameters": "UTM-Parameter"
    },
    "routes": {
      "channels": {
        "channel": {
          "autoresponder": {
            "title": "Autoresponder"
          },
          "title": "Kanal"
        },
        "title": "Kanäle"
      },
      "empty": {
        "emptyFilter": "Keine Treffer gefunden",
        "subtitle": "Ihre Suche ergab keine Treffer.",
        "title": "Keine Elemente gefunden"
      },
      "enquiries": {
        "channel": "Kanal",
        "enquiry": {
          "status": {
            "title": "Status"
          },
          "title": "Anfrage"
        },
        "enquiryType": "Anfragetyp",
        "title": "Anfragen",
        "year": "Jahr "
      },
      "establishments": {
        "code": "Betriebscode",
        "establishment": {
          "activateAlpinebits": "AlpineBits",
          "alpinebits": "AlpineBits",
          "eliteMailExchange": "Elite Mail-Exchange",
          "enquiryReceivers": {
            "description": "Geben Sie hier die E-Mail-Addressen der Empfänger ein.",
            "title": "Empfänger der Anfragen"
          },
          "establishmentCode": "Betriebscode",
          "establishmentInfos": "Betriebsinformationen",
          "phoenixMailExchange": "Phoenix Mail-Exchange",
          "sendEmail": "E-Mail Zustellung",
          "sendEnquiry": "E-Mail Zustellung",
          "title": "Betrieb"
        },
        "name": "Name",
        "title": "Betriebe"
      },
      "settings": {
        "contact": "Betriebsdaten",
        "email": "E-Mail",
        "establishments": "Betriebe",
        "generateTracking": "UTM-Parameter bei Auslieferung an das PMS durch ADDITIVE-Tracking-Parameter überschreiben",
        "languages": {
          "de": "DE",
          "en": "EN",
          "fr": "FR",
          "it": "IT",
          "nl": "NL",
          "pl": "PL"
        },
        "multipleEstablishments": "Weitere Betriebe",
        "name": {
          "de": "Betriebsname - DE",
          "en": "Betriebsname - EN",
          "fr": "Betriebsname - FR",
          "it": "Betriebsname - IT",
          "nl": "Betriebsname - NL",
          "pl": "Betriebsname - PL",
          "title": "Betriebsname"
        },
        "telephone": "Telefon-Nr.",
        "title": "Einstellungen",
        "tracking": "Tracking"
      }
    },
    "select": {
      "empty": "Keine Optionen gefunden",
      "emptyOption": "Keine Auswahl",
      "noResults": "Keine Ergebnisse gefunden",
      "searchPlaceholder": "Suche"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# Element} other {# Elemente}} ausgewählt"
    },
    "toast": {
      "error": "Ein unerwarteter Fehler ist aufgetreten",
      "success": "Die Aktion war erfolgreich"
    },
    "toasts": {
      "loading": {
        "description": "Etwas Geduld bitte, wir sind fast fertig!",
        "title": "Das dauert länger als erwartet."
      }
    },
    "uiActions": {
      "back": "Zurück",
      "close": "Schließen",
      "confirm": "Bestätigen",
      "delete": "Löschen",
      "edit": "Bearbeiten",
      "export": "Exportieren",
      "info": "Informationen",
      "menu": "Menü",
      "more": "Mehr",
      "reset": "Zurücksetzen",
      "save": "Speichern",
      "search": "Suchen"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "Keine Organisation gefunden",
        "organizationSettings": "Organisationseinstellungen",
        "searchPlaceholder": "Suchen"
      },
      "appsList": {
        "goToApps": "zu den Apps",
        "myApps": "Meine Apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Weitere Apps entdecken"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "Diese Kategorie kann nicht gelöscht werden, da sie noch Inhalte enthält.",
      "titleError": "Achtung!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# Unterkategorie} other {# Unterkategorien}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Vorschau",
        "save": "Speichern"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# Eintrag} other {# Einträge}}",
      "filterCount": "{count, plural, =1 {# Eintrag} other {# Einträge}} gefunden",
      "pages": "Seite <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiDateFilter": {
      "label": "Betrachtungszeitraum"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Benutzerdefiniert",
        "last_month": "Letzter Monat",
        "last_thirty_days": "Letzte 30 Tage",
        "last_week": "Letzte Woche",
        "this_month": "Dieser Monat",
        "this_week": "Diese Woche"
      },
      "weekDaysShort": {
        "Fri": "Fr",
        "Mon": "Mo",
        "Sat": "Sa",
        "Sun": "So",
        "Thu": "Do",
        "Tue": "Di",
        "Wed": "Mi"
      }
    },
    "uiDatetimePicker": {
      "date": "Datum",
      "endDate": "Enddatum",
      "endTime": "Enduhrzeit",
      "error": {
        "beforeToday": "Vergangenes Datum",
        "false": "Ungültiges Datum"
      },
      "startDate": "Startdatum",
      "startTime": "Startuhrzeit",
      "time": "Uhrzeit"
    },
    "uiDiscardChanges": {
      "discardAction": "Verwerfen",
      "message": "Sollen ungespeicherte Änderungen verworfen werden?",
      "title": "Achtung!"
    },
    "uiEditor": {
      "actions": {
        "bold": "Fett",
        "bulletList": "Aufzählungsliste",
        "code": "Code",
        "headings": {
          "h1": "Überschrift 1",
          "h2": "Überschrift 2",
          "h3": "Überschrift 3",
          "h4": "Überschrift 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Trennlinie",
        "italic": "Kursiv",
        "link": "Link",
        "numberedList": "Numerierte Liste",
        "quote": "Zitat",
        "underline": "Unterstrichen"
      },
      "urlDialog": {
        "openInNewTab": "In neuem Tab öffnen",
        "save": "Speichern",
        "text": "Text",
        "type": {
          "name": "Link-Typ",
          "options": {
            "email": "E-Mail",
            "tel": "Telefon",
            "weblink": "Weblink"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "Diese E-Mail-Adresse ist nicht gültig",
          "tel": "Diese Telefonnummer ist nicht gültig",
          "weblink": "Diese URL ist nicht gültig"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filtern",
      "daterange": {
        "end": "bis",
        "start": "von"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Wert überschreitet die empfohlene Länge von {recommendedLength} Zeichen"
    },
    "uiFormDialog": {
      "save": "Speichern"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {Ein Element} other {# Elemente}} gelöscht",
      "common": {
        "bulkDelete": {
          "action": "Alle löschen",
          "description": "Die ausgewählten Inhalte werden dadurch unwiederruflich gelöscht und können nicht mehr wiederhergestellt werden.<br/>Sollen die ausgewählten Inhalte wirklich gelöscht werden?"
        },
        "deleteContent": {
          "description": "Der Inhalt wird dadurch unwiederruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        }
      },
      "delete": "Löschen",
      "empty": "Es gibt noch keine Inhalte.<br/>Klicken Sie auf den untenstehenden Button um einen Inhalt zu erstellen.",
      "error": "Ein unerwarteter Fehler ist aufgetreten.",
      "errors": {
        "rate_in_use": "Diese Rate kann nicht gelöscht werden, da Sie in einem Preiszeitraum verwendet wird.",
        "title": "Achtung!",
        "used_in_post": "Diese Person kann nicht gelöscht werden, da sie in einem Beitrag verwendet wird.",
        "vouchers_left": "Dieser Inhalt kann nicht gelöscht werden, da er in einem Gutschein verwendet wird."
      },
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "properties": {
        "published": "öffentlich",
        "unpublished": "nicht öffentlich"
      }
    },
    "uiMonthDayPicker": {
      "day": "Tag",
      "month": "Monat"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "Weitere Filter",
        "error": {
          "retryButtonText": "Es ist ein Fehler aufgetreten! Jetzt neu versuchen"
        },
        "navigationDrawer": {
          "title": "Weitere Filter"
        }
      },
      "searchInputDefaultPlaceholder": "Durchsuchen"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Zurück",
        "endOnboarding": "Jetzt loslegen",
        "next": "Weiter",
        "skip": "Überspringen"
      }
    },
    "uiPublishResource": {
      "description": "Wählen Sie hier aus welche Sprachen Sie veröffentlichen möchten. Zudem können Sie den Rahmen der Veröffentlichung zeitlich einschränken.",
      "descriptionNoLang": "Wählen Sie den Zeitpunkt für die Veröffentlichung aus.",
      "errors": {
        "after": "Muss nach dem Startdatum sein",
        "before": "Muss vor dem Enddatum sein",
        "required": "Darf nicht leer sein"
      },
      "languages": "Sprachen",
      "limitTime": "Veröffentlichungszeitraum einschränken",
      "save": "Speichern",
      "settings": "Einstellungen",
      "title": "Veröffentlichen"
    },
    "uiRangeSlider": {
      "errorMessage": "Der Wert muss eine positive Ganzzahl sein",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "Keine Daten vorhanden",
      "error": "Ein Fehler ist aufgetreten",
      "retry": "Nochmal versuchen",
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiTags": {
      "error": "Dieser Wert wurde bereits hinzugefügt",
      "interests": {
        "interests": "Interessen",
        "travelMotivation": "Reisemotive",
        "travelTime": "Reisezeiten"
      },
      "placeholder": "Tag hinzufügen",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Ungültige Zeitangabe",
      "timeUnit": "Uhr"
    },
    "uiToast": {
      "success": "Aktion erfolgreich durchgeführt",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten"
    }
  }], ["en", {
    "app": {
      "title": "ADDITIVE+ INQUIRIES"
    },
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "INQUIRIES",
        "presentationName": "ADDITIVE+ INQUIRIES"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "VOUCHERS",
        "presentationName": "ADDITIVE+ VOUCHERS"
      },
      "4": {
        "name": "CONTENTS",
        "presentationName": "ADDITIVE+ CONTENTS"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GUEST INTRANET",
        "presentationName": "ADDITIVE+ GUEST INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Deleted User",
      "description": "Find the chronological list of all your users' activities within the app below. Please note that the users must be part of your organization for you to see their activities.",
      "empty": "No activities available",
      "entries": "Entries",
      "page": "Page",
      "time": "{time}",
      "title": "Activity Log"
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      }
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS integrations",
            "campaigns": "Campaigns",
            "landingPages": "Landing pages",
            "leadIntegrations": "Lead integrations",
            "pmsIntegrations": "PMS integrations",
            "settings": "Settings",
            "surveys": "Surveys",
            "thirdPartyTokens": "ADDITIVE+ APPS interfaces",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Content",
            "contentType": "Content Type"
          }
        },
        "description": "The object can not be deleted, because it is used by other contents. Make sure to resolve the remaining dependencies in order to delete this object.",
        "title": "The object is in use"
      },
      "discardChanges": {
        "continueEditing": "Continue editing",
        "discardAction": "Discard",
        "message": "Do you want to discard the unsaved changes?",
        "title": "Attention!"
      },
      "error": {
        "message": "An unexpected error has occurred. Please try again later.",
        "title": "Something went wrong"
      },
      "noPermission": {
        "message": "You do not have permission to perform this action. Please contact your administrator.",
        "title": "Missing permission"
      }
    },
    "errors": {
      "channelCodeExists": "This channel ID is already being used.",
      "email": "Invalid email address",
      "establishmentCodeExists": "This company ID is already in use.",
      "failedDependency": "This channel cannot be deleted because there are entries associated with it.",
      "multipleEmailsValidation": "Invalid email addresses",
      "nameExists": "This name is already in use.",
      "required": "This value must not be empty.",
      "title": "Error",
      "wrongPlaceholder": "You may only use {name} as a placeholder in the subject line."
    },
    "global": {
      "actions": {
        "activate": "Enable",
        "cancel": "Cancel",
        "change": "Change",
        "create": "Create",
        "deactivate": "Disable",
        "delete": "Delete",
        "download": "Download",
        "export": "Export",
        "false": "Disabled",
        "filter": "Filter",
        "logout": "Log out",
        "more": "More",
        "remove": "Remove",
        "rename": "Rename",
        "retry": "Retry",
        "save": "Save",
        "search": "Search",
        "true": "Enabled"
      },
      "dialog": {
        "change": {
          "alpinebits": "AlpineBits",
          "casablanca": "Casablanca",
          "description": "Are you sure you want to change this value?",
          "generateTracking": "Overwrite UTM parameters",
          "mts": "MTS",
          "multipleEstablishments": "More companies",
          "reGuest": "Re:Guest"
        },
        "delete": "Are you sure you want to delete this element?",
        "error": {
          "sendEnquiry": {
            "description": "Email delivery cannot be activated since no recipients have been specified.",
            "title": "Warning!"
          }
        }
      },
      "email": "Email Address",
      "emails": "Email Addresses",
      "false": "No",
      "languages": {
        "de": "German",
        "en": "English",
        "fr": "French",
        "it": "Italian",
        "nl": "Dutch language",
        "pl": "Polish"
      },
      "name": "Name",
      "status": {
        "false": "Not active",
        "true": "Active"
      },
      "telephone": "Phone Number",
      "toast": {
        "deleteLanguage": {
          "success": "{lang} has been successfully deleted."
        },
        "error": {
          "savedChanges": "An unexpected error occurred."
        },
        "success": {
          "savedChanges": "The action was successful."
        },
        "update": {
          "actionLabel": "Reload",
          "description": "Unsaved changes will be lost.",
          "title": "Update available"
        }
      },
      "true": "Yes"
    },
    "instance": {
      "channels": {
        "channel": {
          "alpinebits": "AlpineBits",
          "alpinebitsSubheader": "AlpineBits",
          "autoresponder": {
            "subject": "Subject",
            "text": "Text"
          },
          "autoresponderSubheader": "Autoresponder",
          "autoresponderText": "Email Content",
          "casablanca": "Casablanca",
          "casablancaCompanyId": "Company ID",
          "casablancaCustomerId": "Customer ID",
          "casablancaSubheader": "Casablanca",
          "channelCode": "Channel ID",
          "channelInfos": "Channel Information",
          "cooperationChannel": "Cooperation Channel",
          "eliteMailExchange": "Elite Mail-Exchange",
          "enquiryMailIntro": "Email Introduction",
          "enquiryReceivers": {
            "description": "Please enter the recipients' email addresses.",
            "title": "Recipients of Inquiries"
          },
          "establishmentCode": "Company ID",
          "establishmentConfig": "Company Configurations",
          "establishmentsRequired": "Company required",
          "medium": "Medium",
          "mts": "MTS",
          "mtsId": "Company ID",
          "mtsPassword": "Password",
          "mtsSubheader": "MTS",
          "mtsUsername": "Username",
          "name": "Name",
          "phoenixMailExchange": "Phoenix Mail Exchange",
          "reGuest": "Re:Guest",
          "reGuestCustomerId": "Company ID",
          "reGuestFacilityCode": "Facility Code",
          "reGuestPassword": "Password",
          "reGuestSubheader": "Re:Guest",
          "reGuestUsername": "Username",
          "sendAutoresponder": "Activate Autoresponder",
          "sendEmail": "Email Delivery",
          "sendEnquiry": "Email Delivery",
          "source": "Source",
          "tracking": "Tracking"
        },
        "dialogs": {
          "create": {
            "label": "Name",
            "title": "Create new channel"
          },
          "delete": {
            "content": "Are you sure you want to delete this channel?",
            "title": "Delete channel “{name}”"
          },
          "deleteError": {
            "content": "This channel cannot be deleted as there are entries assigned to it.",
            "title": "Whoops!"
          },
          "enquiryReceivers": {
            "placeholder": "Email",
            "title": "Recipients of Inquiries"
          },
          "establishmentCode": {
            "error": "This value must not be empty.",
            "label": "Code"
          },
          "integrationError": {
            "alpinebits": "A company ID is required to activate this channel.",
            "casablanca": "Company ID and customer ID are required to activate this channel.",
            "mts": "Company ID, username and password are required to activate this channel.",
            "reGuest": "Company ID, username and password are required to activate this channel.",
            "title": "Warning!"
          },
          "rename": {
            "label": "Name",
            "title": "Rename channel"
          },
          "sendEnquiryError": {
            "content": "At least one recipient is required to activate this channel.",
            "title": "Whoops!"
          }
        },
        "infos": {
          "alpinebits": "AlpineBits",
          "casablanca": "Casablanca",
          "email": "Email Address",
          "mts": "MTS",
          "phoenix": "Phoenix",
          "reguest": "Re:Guest"
        }
      },
      "enquiries": {
        "dialogs": {
          "exportError": {
            "content": "An error occurred while exporting the data. Please try again later!",
            "title": "Whoops!"
          }
        },
        "enquiry": {
          "additionals": "Additional Information",
          "attachments": "Attachments",
          "createdAt": "created on",
          "delete": "Delete inquiry",
          "dialogs": {
            "deleteEnquiry": {
              "content": "The inquiry will be irrevocably deleted. Are you sure you want to continue?",
              "title": "Delete inquiry"
            }
          },
          "error": "An error occurred while loading the elements.",
          "establishment": "Company",
          "establishments": "Companies",
          "false": "No",
          "genderIntl": "Gender",
          "language": "Inquiry Language",
          "organisation": {
            "addressCountry": "Country",
            "catalogue": "Catalog",
            "email": "Email Address",
            "faxNumber": "Fax Number",
            "image": "Image",
            "language": "Language",
            "legalName": "Name",
            "logo": "Logo",
            "nationality": "Nationality",
            "newsletter": "Newsletter",
            "postOfficeBoxNumber": "P.O. Box Number",
            "postalCode": "Zip Code",
            "streetAddress": "Street",
            "taxId": "VAT no.",
            "telephone": "Phone Number",
            "title": "Organization",
            "url": "Website",
            "vatId": "VAT no."
          },
          "organisationLocality": "",
          "person": {
            "additional": "Additional Information",
            "additionalName": "Middle Name",
            "addressCountry": "Country",
            "addressLocality": "City",
            "birthDate": "Birthday",
            "catalogue": "Catalog",
            "email": "Email Address",
            "familyName": "Last Name",
            "faxNumber": "Fax Number",
            "fullname": "Full Name",
            "gender": "Gender",
            "genderOptions": {
              "f": "Female",
              "m": "Male",
              "none": "Not specified"
            },
            "givenName": "First Name",
            "honorificPrefix": "Salutation",
            "image": "Image",
            "language": "Language",
            "mobilephone": "Cell Phone Number",
            "nationality": "Nationality",
            "newsletter": "Newsletter",
            "postOfficeBoxNumber": "P.O. Box Number",
            "postalCode": "Zip Code",
            "salutationLanguage": "Salutation Language",
            "streetAddress": "Street",
            "taxId": "VAT no.",
            "telephone": "Phone Number",
            "title": "Contact Information",
            "url": "Website"
          },
          "personLocality": "",
          "product": {
            "title": "Product"
          },
          "roomStays": {
            "adults": "Adults",
            "amount": "Price",
            "children": "Children",
            "endDate": "Departure",
            "mealPlan": "Meal Plan",
            "mealPlanRates": {
              "1": "All Inclusive",
              "10": "Full Board",
              "12": "Half Board",
              "14": "Without Catering",
              "3": "Bed & Breakfast"
            },
            "number": {
              "0": "Stay No. 1",
              "1": "Stay No. 2",
              "2": "Stay No. 3",
              "3": "Stay No. 4",
              "4": "Stay No. 5",
              "5": "Stay No. 6",
              "6": "Stay No. 7"
            },
            "offer": "Offer",
            "ratePlan": "Rate Plan / Offer",
            "roomClassificationCode": "Accommodation Type",
            "roomClassificationCodes": {
              "13": "Apartment",
              "3": "Suite",
              "42": "Room"
            },
            "roomType": "Accommodation Name",
            "startDate": "Arrival",
            "title": "Stays",
            "year": "{count, plural, =1 {year} other {years}}"
          },
          "status": {
            "alpinebits": "Fetched by AlpineBits",
            "casablanca": "Fetched by Casablanca",
            "emptyText": "Status not available",
            "enquiryReceivers": "Delivered to inquirer",
            "failure": "Failure",
            "mts": "Fetched by MTS",
            "pending": "Pending",
            "pickingUpBy": "Fetched by",
            "reGuest": "Fetched by Re:Guest",
            "receivers": "Delivered to recipient",
            "shippingTo": "Delivered to",
            "success": "Success"
          },
          "superAdminTrackingMediumDisplay": "Medium",
          "superAdminTrackingSourceDisplay": "Source",
          "text": "Text",
          "title": "Title",
          "tracking": {
            "campaign": "Campaign",
            "cooperationMedium": "Medium",
            "cooperationSource": "Source",
            "entryPage": "Entry Page",
            "medium": "Medium",
            "referrer": "Referrer",
            "source": "Source",
            "title": "Tracking",
            "tracking": "Tracking"
          },
          "true": "Yes"
        },
        "export": {
          "action": "Export",
          "dialog": {
            "dateRange": "Time period",
            "description": "Select the desired list of inquiries you would like to download.",
            "noDateRangeSelected": "No time period selected",
            "options": {
              "all": "Download all inquiries",
              "filtered": "Download currently filtered inquiries only",
              "timePeriod": "Download inquiries by time period"
            },
            "title": "Download inquiries"
          },
          "toast": {
            "message": "Export is being prepared..."
          }
        },
        "filter": {
          "button": "Filter",
          "options": {
            "documents": "Documents",
            "images": "Images",
            "pdf": "PDF"
          },
          "title": "Filter by"
        },
        "groups": {
          "date": {
            "lastWeek": "Last week",
            "thisMonth": "This month",
            "thisWeek": "This week",
            "today": "Today",
            "yesterday": "Yesterday"
          }
        },
        "listView": {
          "channel": "Channel",
          "date": "Date",
          "email": "Email Address",
          "name": "Name",
          "status": "Status"
        },
        "titleCount": "Inquiries {count}"
      },
      "establishments": {
        "dialogs": {
          "code": {
            "label": "Company ID",
            "title": "Change company ID"
          },
          "create": {
            "code": "Company ID",
            "label": "Company Name",
            "title": "Create new company"
          },
          "delete": {
            "content": "Are you sure you want to delete this company?",
            "title": "Delete company “{name}”"
          },
          "enquiryReceivers": {
            "placeholder": "Email Address",
            "title": "Inquiry Receivers"
          },
          "rename": {
            "label": "Company Name",
            "title": "Rename company"
          },
          "sendEnquiryError": {
            "content": "At least one recipient must be specified in order to enable this feature.",
            "title": "Whoops!"
          }
        },
        "establishment": {
          "alpinebits": "AlpineBits",
          "alpinebitsSubheader": "AlpineBits",
          "code": "Company ID",
          "enquiryReceivers": "Recipients of Inquiries",
          "establishmentInfos": "Company Information",
          "phoenixMailExchange": "Phoenix Mail Exchange",
          "sendEmail": "Email Delivery",
          "sendEnquiry": "Email Delivery"
        }
      }
    },
    "languageSelect": {
      "add": "Add language",
      "ar": "Arabic",
      "de": "German",
      "en": "English",
      "fr": "French",
      "it": "Italian",
      "nl": "Dutch",
      "pl": "Polish",
      "remove": "Remove language",
      "removeDescription": "Are you sure you want to remove this language?",
      "ru": "Russian"
    },
    "pageNotFound": {
      "button": "Homepage",
      "content": "The page you were looking for could not be found!<br>The URL may be incorrect or the page may have been removed or renamed.",
      "title": "Page not found!"
    },
    "reports": {
      "adultCount": {
        "title": "Adult Count",
        "value": "{count, plural, =0 {no adult} =1 {# adult} other {# adults}}"
      },
      "adultCountMonthData": {
        "description": "In the following table, you will find a list of inquiries made in the selected period based on the number of adults indicated in the respective inquiry. You will also find out what proportion of these fall into the group \"adults only\" or \"adults with children\".",
        "title": "Requested stays by number of indicated adults"
      },
      "age": {
        "title": "Age",
        "value": "{count, plural, =1 {# year} other {# years}}"
      },
      "campaign": "Campaign",
      "channelsData": "Channels",
      "childAgeData": "Number of indicated children by age",
      "childCount": {
        "title": "Child Count",
        "value": "{count, plural, =0 {no children} =1 {# child} other {# children}}"
      },
      "childCountData": "Requested stays by number of children indicated in the inquiry",
      "description": "Get a quick overview of all inquiries achieved during the period under review as well as some further insights into various detailed evaluations.",
      "filter": {
        "all": "All",
        "allChannels": "All channels",
        "campaign": "Campaign",
        "channel": "Channel",
        "endDate": "End Date",
        "medium": "Medium",
        "none": "None",
        "source": "Source",
        "startDate": "Start Date",
        "timespan": "Timespan",
        "updateFilter": "Update filter"
      },
      "general": {
        "count": "Inquiries in this period",
        "countLastPeriod": "In the compare period {from}-{to}",
        "countLastYear": "In the compare period {year}",
        "daysBeforeArrivalAverage": "Ø Enquiry Lead Time in Days",
        "perDay": "Ø Number of inquiries per day",
        "perWeek": "Ø Number of inquiries per week",
        "title": "Inquiries",
        "yearToDate": "Compared to previous year"
      },
      "generalGuest": {
        "averageAdults": "Ø Number of adults",
        "averageChildren": "Ø Number of children",
        "averageChildrenAge": "Ø Age of children in years",
        "averageStays": "Ø Length of stay in days"
      },
      "guestNightsPerMonthData": {
        "description": "In the overview below you can learn more about all overnight stays per month requested in the selected period. Each indicated person is taken into account and categorized either as an adult or child.",
        "title": "Requested nights per month"
      },
      "guestsPerArrivalMonthData": {
        "description": "The following statistics show the number of arrivals per month requested in the selected period. Analogous to the previous detailed evaluations, a distinction is made between indicated adults and children.",
        "title": "Requested arrivals per month"
      },
      "languages": {
        "de": "German",
        "en": "English",
        "fr": "French",
        "it": "Italian",
        "nl": "Dutch language",
        "pl": "Polish",
        "ru": "Russian",
        "se": "Spanish"
      },
      "languagesData": "Language",
      "medium": "Medium",
      "source": "Source",
      "stayCountData": {
        "description": "The following table categorizes all inquiries generated in the selected period based on the respective length of stay, while distinguishing between requests where only adults are indicated and those where children are mentioned as well.",
        "title": "Requested stays by length of stay"
      },
      "stayRequests": "Inquiries",
      "tableFields": {
        "adultOnly": "Adults Only",
        "adults": "Adults",
        "age": "Age",
        "campaign": "Campaign",
        "channel": "Channel",
        "children": "Children",
        "count": "Count",
        "daily": "Daily",
        "days": "Days",
        "language": "Language",
        "medium": "Medium",
        "month": "Month",
        "name": "Name",
        "source": "Source",
        "stays": {
          "4to6": "4-6",
          "7to14": "7-14",
          "over15": "> 15",
          "under4": "1-3"
        },
        "total": "Total",
        "weekly": "Weekly",
        "withChildren": "With Children"
      },
      "title": "Reports",
      "utmParameters": "UTM Parameters"
    },
    "routes": {
      "channels": {
        "channel": {
          "autoresponder": {
            "title": "Autoresponder"
          },
          "title": "Channel"
        },
        "title": "Channels"
      },
      "empty": {
        "emptyFilter": "No matches found",
        "subtitle": "Your search did not return any results.",
        "title": "No items found"
      },
      "enquiries": {
        "channel": "Channel",
        "enquiry": {
          "status": {
            "title": "Status"
          },
          "title": "Inquiry"
        },
        "enquiryType": "Inquiry Type",
        "title": "Inquiries",
        "year": "Year"
      },
      "establishments": {
        "code": "Company ID",
        "establishment": {
          "activateAlpinebits": "AlpineBits",
          "alpinebits": "AlpineBits",
          "eliteMailExchange": "Elite Mail Exchange",
          "enquiryReceivers": {
            "description": "Enter the recipients' email addresses.",
            "title": "Recipients of Inquiries"
          },
          "establishmentCode": "Company ID",
          "establishmentInfos": "Company Information",
          "phoenixMailExchange": "Phoenix Mail Exchange",
          "sendEmail": "Email Delivery",
          "sendEnquiry": "Email Delivery",
          "title": "Company"
        },
        "name": "Name",
        "title": "Companies"
      },
      "settings": {
        "contact": "Company Data",
        "email": "Email Address",
        "establishments": "Companies",
        "generateTracking": "Overwrite UTM parameters with ADDITIVE tracking parameters before delivery to the PMS ",
        "languages": {
          "de": "DE",
          "en": "EN",
          "fr": "FR",
          "it": "IT",
          "nl": "NL",
          "pl": "PL"
        },
        "multipleEstablishments": "Other Companies",
        "name": {
          "de": "Company Name - DE",
          "en": "Company Name - EN",
          "fr": "Company Name - FR",
          "it": "Company Name - IT",
          "nl": "Company Name - NL",
          "pl": "Company Name - PL",
          "title": "Company Name"
        },
        "telephone": "Phone Number",
        "title": "Settings",
        "tracking": "Tracking"
      }
    },
    "select": {
      "empty": "No option found",
      "emptyOption": "No selection",
      "noResults": "No results found",
      "searchPlaceholder": "Search"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# element} other {# elements}} selected"
    },
    "toast": {
      "error": "An unexpected error occurred",
      "success": "Action successfully completed"
    },
    "toasts": {
      "loading": {
        "description": "Please hold on, we're still loading!",
        "title": "This is taking longer than expected."
      }
    },
    "uiActions": {
      "back": "Back",
      "close": "Close",
      "confirm": "Confirm",
      "delete": "Delete",
      "edit": "Edit",
      "export": "Export",
      "info": "Informations",
      "menu": "Menu",
      "more": "More",
      "reset": "Reset",
      "save": "Save",
      "search": "Search"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "No organization found",
        "organizationSettings": "Organization settings",
        "searchPlaceholder": "Search"
      },
      "appsList": {
        "goToApps": "Go to apps",
        "myApps": "My apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Discover other apps"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "This category cannot be deleted, because it still contains content.",
      "titleError": "Attention!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# subcategory} other {# subcategories}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Preview",
        "save": "Save"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# entry} other {# entries}}",
      "filterCount": "{count, plural, =1 {# entry} other {# entries}} found",
      "pages": "Page <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiDateFilter": {
      "label": "Observation period"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Custom date",
        "last_month": "Last month",
        "last_thirty_days": "Last 30 days",
        "last_week": "Last week",
        "this_month": "This month",
        "this_week": "This week"
      },
      "weekDaysShort": {
        "Fri": "Fri",
        "Mon": "Mon",
        "Sat": "Sat",
        "Sun": "Sun",
        "Thu": "Thu",
        "Tue": "Tue",
        "Wed": "Wed"
      }
    },
    "uiDatetimePicker": {
      "date": "Date",
      "endDate": "End date",
      "endTime": "End time",
      "error": {
        "beforeToday": "Past date",
        "false": "Invalid date"
      },
      "startDate": "Start date",
      "startTime": "Start time",
      "time": "Time"
    },
    "uiDiscardChanges": {
      "discardAction": "Discard",
      "message": "Do you want to discard the unsaved changes?",
      "title": "Attention!"
    },
    "uiEditor": {
      "actions": {
        "bold": "Bold",
        "bulletList": "List",
        "code": "Code",
        "headings": {
          "h1": "Heading 1",
          "h2": "Heading 2",
          "h3": "Heading 3",
          "h4": "Heading 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Horizontal line",
        "italic": "Italic",
        "link": "Link",
        "numberedList": "Numbered list",
        "quote": "Quote",
        "underline": "Underline"
      },
      "urlDialog": {
        "openInNewTab": "Open in new tab",
        "save": "Save",
        "text": "Text",
        "type": {
          "name": "Link type",
          "options": {
            "email": "Email",
            "tel": "Phone",
            "weblink": "Web link"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "This email address is not valid",
          "tel": "This phone number is not valid",
          "weblink": "This URL is not valid"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filter",
      "daterange": {
        "end": "until",
        "start": "from"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Value exceeds the recommended length of {recommendedLength} characters"
    },
    "uiFormDialog": {
      "save": "Save"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {One element} other {# elements}} deleted",
      "common": {
        "bulkDelete": {
          "action": "Delete all",
          "description": "The selected contents will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete the selected contents?"
        },
        "deleteContent": {
          "description": "The content will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
          "title": "Attention!"
        }
      },
      "delete": "Delete",
      "empty": "There are no existing contents.<br/>Click the button below to create a new content.",
      "error": "An unexpected error occurred.",
      "errors": {
        "rate_in_use": "This rate cannot be deleted, since it is used in a price timespan.",
        "title": "Attention!",
        "used_in_post": "This person cannot be deleted, since it is used in a post.",
        "vouchers_left": "This content cannot be deleted, since it is used in a voucher."
      },
      "noMatch": "Your search did not return any results.",
      "properties": {
        "published": "published",
        "unpublished": "unpublished"
      }
    },
    "uiMonthDayPicker": {
      "day": "Day",
      "month": "Month"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "More filters",
        "error": {
          "retryButtonText": "An error has occurred! Try again now"
        },
        "navigationDrawer": {
          "title": "More filters"
        }
      },
      "searchInputDefaultPlaceholder": "Search"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Back",
        "endOnboarding": "Get started now",
        "next": "Next",
        "skip": "Skip"
      }
    },
    "uiPublishResource": {
      "description": "Choose which language you would like to publish. You can also limit the time frame for the publication.",
      "descriptionNoLang": "Select the time to publish the content.",
      "errors": {
        "after": "Must be after start date",
        "before": "Must be before end date",
        "required": "Required"
      },
      "languages": "Languages",
      "limitTime": "Limit the time frame",
      "save": "Save",
      "settings": "Settings",
      "title": "Publish"
    },
    "uiRangeSlider": {
      "errorMessage": "The value must be a positive integer",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "No data available",
      "error": "An error has occurred",
      "retry": "Try again",
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiTags": {
      "error": "This value has already been added",
      "interests": {
        "interests": "Interests",
        "travelMotivation": "Travel motives",
        "travelTime": "Seasons"
      },
      "placeholder": "Add tag",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Invalid time value",
      "timeUnit": ""
    },
    "uiToast": {
      "success": "Action completed successfully",
      "unexpectedError": "An unexpected error has occurred"
    }
  }]];
});